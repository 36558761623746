import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="update-subscription"
export default class extends Controller {
  static targets = ["status", "reason", "container"];
  cancelled_reasons = ["Deceased", "Installing solar panels", "Billing issues - Payment processing delays",
    "Billing issues - Incorrect invoices", "Billing issues - Program too complicated/ Takes too much time",
    "No consolidated billing available", "Moving out of state - No community solar available",
    "Moving in state - Follow up needed", "Low savings", "Don't remember signing up",
    "Transferring account to someone else", "Other"];
  rejected_reasons = ["Utility account closed", "Has solar panels", "With another host", "Other"];

  connect() {
    this.checkStatus();
  }

  checkStatus() {
    if (this.statusValue === "cancelled") {
      this.containerTarget.classList.remove("hidden");
      this.reasonTarget.disabled = false;
      var options = this.cancelled_reasons.map(reason => `<option value="${reason}">${reason}</option>`);
      this.reasonTarget.innerHTML = options.join('');
    }
    else if (this.statusValue === "rejected") {
      this.containerTarget.classList.remove("hidden");
      this.reasonTarget.disabled = false;
      var options = this.rejected_reasons.map(reason => `<option value="${reason}">${reason}</option>`);
      this.reasonTarget.innerHTML = options.join('');

    } else {
      this.containerTarget.classList.add("hidden");
      this.reasonTarget.disabled = true;
    }
  }

  get statusValue() {
    return this.statusTarget.value;
  }
}